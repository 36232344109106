import { defineStore } from 'pinia'

export const useCacheSession = defineStore('cacheSession', {
  state: (): {
    dataCaching: {
      stores: Store[]
      materials: Record<string, Material[]>
      offersGroup: GroupOffer[]
    }
  } => ({
    dataCaching: { offersGroup: [], stores: [], materials: {} }
  }),

  getters: {
    getStores: (state) => {
      return state.dataCaching.stores
    },
    getOffersGroup: (state) => {
      return state.dataCaching.offersGroup
    },
    getMaterials: (state) => (key: string) => {
      return state.dataCaching.materials[`${key}${useProfileId()}`] || []
    }
  },

  actions: {
    cacheStores(stores: Store[]) {
      this.dataCaching.stores.push(...stores)
    },
    cacheOffersGroup(offersGroup: GroupOffer[]) {
      this.dataCaching.offersGroup = [...offersGroup]
    },
    cacheMaterials(key: string, materials: Material[]) {
      this.dataCaching.materials[`${key}${useProfileId()}`] = materials
    },
    clearCache() {
      this.dataCaching = { offersGroup: [], stores: [], materials: {} }
      const { clearAnnouncements } = useAnnouncementStore()
      clearAnnouncements()
    },
    toggleValueFavourite(materialId: number, valueFavourite: boolean) {
      for (const key of Object.keys(this.dataCaching.materials)) {
        const index = this.dataCaching.materials[key].findIndex(
          (element) => element.id === materialId
        )
        if (index !== -1) {
          this.dataCaching.materials[key][index].hasFavoritedByAgent =
            valueFavourite
          this.dataCaching.materials[key][index].has_favorited = valueFavourite
        }
      }
    }
  },
  persist: [{ paths: ['dataCaching'], storage: persistedState.sessionStorage }]
})
