import { defineStore } from 'pinia'
import type {
  Ad,
  TypesAnnouncements
} from '~~/composables/useMenuModel'
const { groupBy, adModel } = useMenuModel()

export const useAnnouncementStore = defineStore('announcements', {
  state: (): {
    announcements: Ad[]
    typesAnnouncements: TypesAnnouncements
    errors: any
  } => ({
    announcements: [],
    typesAnnouncements: {},
    errors: null
  }),

  getters: {
    getAnnouncements: (state) => {
      return state.typesAnnouncements
    },
    getErrors: (state) => {
      return state.errors
    }
  },

  actions: {
    clearAnnouncements() {
      this.announcements = []
      this.typesAnnouncements = {}
    },
    async loadAnnouncements() {
      if (!this.announcements.length) {
        const { data: itemsAds, error: errorAds } = await useNuxtApiFetch(
          '/laravel/announcements',
          {
            query: {
              domain: useDomainHeader()
            },

            transform: (data: SuccessResponse<Ad[]>) => {
              return data.data.map(adModel)
            }
          }
        )
        if (itemsAds.value?.length) {
          this.announcements = itemsAds.value
          this.typesAnnouncements = getAnnouncementsSortedByType(this.announcements)
        } else if (errorAds.value != null) {
          this.errors = errorAds.value
        }
      }
      return this.typesAnnouncements
    },
    goToRelatedAd(ad:Ad) {
      const localePath = useLocalePath()
      if (ad.related_id) {
        if (ad.related_type === 'App\\Models\\Material') {
          useRouter().push(
            localePath({
              name: 'materials-id',
              params: { id: ad.related_id }
            })
          )
        } else if (ad.related_type === 'App\\Models\\Store') {
          useRouter().push(
            localePath({
              name: 'stores-id',
              params: { id: ad.related_id }
            })
          )
        }
      } else if (ad.link) {
        window.open(ad.link, '_blank')?.focus()
      }
    }
  }
/*   persist: [
    { paths: ['uuid'], storage: persistedState.sessionStorage },
    {
      paths: ['items', 'stores_tables', 'hasToken', 'loading'],
      storage: persistedState.localStorage
    }
  ] */
})
const AdvertTypeEnum = {
  homeBasicAdvert: 'homeBasicAdvert',
  homeEndPage: 'homeEndPage',
  homeCrossMaterial: 'homeCrossMaterial'
}
function getAdvertType(location: number) {
  switch (location) {
    case 1:
      return AdvertTypeEnum.homeBasicAdvert
    case 2:
      return AdvertTypeEnum.homeEndPage
    default:
      return AdvertTypeEnum.homeCrossMaterial
  }
}
function getAnnouncementsSortedByType(announcementInitial: Ad[]) {
  const typesAnnouncements: TypesAnnouncements = {
    homeBasicAdverts: [],
    homeEndPageAdverts: [],
    homeCrossMaterialsAdverts: []
  }
  const announcement = announcementInitial.filter(
    (element) => element.location !== null
  )

  if (announcement.length > 0) {
    typesAnnouncements.homeBasicAdverts = announcement.filter(
      (element) => getAdvertType(element.location!) === 'homeBasicAdvert'
    )
    typesAnnouncements.homeEndPageAdverts = announcement.filter(
      (element) => getAdvertType(element.location!) === 'homeEndPage'
    )
    const announcementCrossMaterialNotGrouped = announcement.filter(
      (element) => getAdvertType(element.location!) === 'homeCrossMaterial'
    )
    const announcementCrossMaterialNotGroupedByLocationMapped = groupBy(
      announcementCrossMaterialNotGrouped,
      (obj) => obj.location
    )
    typesAnnouncements.homeCrossMaterialsAdverts = Array.from(
      announcementCrossMaterialNotGroupedByLocationMapped.values()
    )
  }

  return typesAnnouncements
}
